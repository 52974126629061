<template>
<div class="col-md-12 mx-auto">
  <div class="card rounded shadow-lg">
    <div class="card-body">
      <div class="card-title text-center">
        <h1>Tablas</h1>
      </div>
      <br>
      <div class="row">
        <div class="col-10">
          <div class="form-group row">
            <form v-on:submit.prevent="searchItems(keywords)">
              <div class="input-group">
                <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button type="submit" class="btn btn-outline-primary">Buscar</button>
                </div>
              </div>                
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="">
            <button v-on:click="$router.push({ name: 'CreateTabla', params: {add: true} });" class="btn btn-primary"><i data-feather="plus-circle"></i>Crear Tabla</button>
          </div>
          <br>
          <div v-if="filter" class="alert alert-primary" role="alert">
            {{ keywords }}
            <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
          </div>
          <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="list-doc" role="tabpanel" aria-labelledby="list-doc-list">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td></td>
                      <td>Tipo de tabla</td>
                      <td>No. Versión</td>
                      <td>Cód. Dependencia</td>
                      <td>Dependencia</td>
                      <td>Cód. Serie</td>
                      <td>Serie</td>
                      <td>Años en Gestión</td>
                      <td>Años en Central</td>
                      <td>Conservación</td>
                      <td>Eliminación</td>
                      <td>Digitalización</td>
                      <td>Selección</td>
                      <td>Procedimiento</td>
                      <td>Activa</td>
                      <!--
                      <td>Cambiar primera hoja</td>
                      -->
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items">
                      <td class="text-center">
                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>                                              
                      </td>
                      <td v-on:click="editItem(item._id)" onmouseover="this.style.cursor='pointer'">{{ item.tpotabla_tablas[0] != undefined ? item.tpotabla_tablas[0].tta_nmbre : '' }}</td>
                      <td>{{ item.tav_nmro }}</td>
                      <td>{{ item.dependencia_tablas[0] != undefined ? item.dependencia_tablas[0].dep_cdgo : '' }}</td>
                      <td>{{ item.dependencia_tablas[0] != undefined ? item.dependencia_tablas[0].dep_nmbre : '' }}</td>
                      <td>{{ item.serie_tablas[0] != undefined ? item.serie_tablas[0].ser_cdgo : '' }}</td>
                      <td>{{ item.serie_tablas[0] != undefined ? item.serie_tablas[0].ser_nmbre : '' }}</td>
                      <td>{{ item.tab_tpg }}</td>
                      <td>{{ item.tab_tpc }}</td>
                      <td>{{ item.tab_cnsrvcn | formatBoolean }}</td>
                      <td>{{ item.tab_elmncn | formatBoolean }}</td>
                      <td>{{ item.tab_dgtlzcn | formatBoolean }}</td>
                      <td>{{ item.tab_slccn | formatBoolean }}</td>
                      <td>{{ item.tab_prcdmnto }}</td>
                      <td>{{ item.tab_activa | formatBoolean }}</td>
                      <!--
                      <td>{{ item.tab_cph | formatBoolean }}</td>
                      -->
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div v-if="showProgress" class="col-md-4 mx-auto">
                  <v-progress-linear
                    color="orange"
                    indeterminate
                    rounded
                    height="4"
                  ></v-progress-linear>              
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="list-doc-archivo" role="tabpanel" aria-labelledby="list-archivo-list">...</div>
          </div>
          <br>
          <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
            <i data-feather="delete"></i>Eliminar
          </button>
          <!--{{delItems}}-->
        </div>
        <div class="row justify-content-around" align="center">
          <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import { formatBoolean } from './../../plugins/filters';
import moment from 'moment';
import XLSX from 'xlsx';

export default {
  components: {
    //VIcon
  },
  data(){
    return{
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      showProgress: false,
    }
  },
  filters: {
    formatBoolean
  },
  computed: {
    checkedItems(){
      if (this.delItems.length > 0){
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function()
  {
    this.fetchItems();
  },
  methods: {
    fetchItems()
    {
      this.showProgress = true;
      let uri = '/tablas/' + String(this.$store.state.company);
      this.axios.get(uri)
      .then((response) => {
        this.items = response.data;
        this.showProgress = false;
        this.delItems = [];
      })
      .catch((err) => {
        this.showProgress = false;
      });

    },
    deleteItem(id, index)
    {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/tablas/delete/'+id;
        this.axios.get(uri)
        .then(res => {
          this.items.splice(index, 1);
        })
        .catch(err => console.log(err));
      }
      return;
    },
    deleteItems()
    {
      if (this.delItems.length > 0){
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          for (var i = 0; i < this.delItems.length; i++){
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = '/tablas/delete/' + this.delItems[i];
            this.axios.get(uri)
            .then(res => {
              //this.items.splice(index, 1);
            })
            .catch(err => {
              console.log(err);
              for (var m = 0; m < this.items.length; m++){
                if (this.delItems[i] == this.items[m]._id){
                  alert('No se ha podido eliminar el item ' + this.items[m]);
                  m = this.items.length;
                }
              }
            });
          }
          if (this.filter){
            this.searchItems(this.keywords);
          } else {
            this.fetchItems();
          }
        }
      }
      return;
    },
    checkAllItems(){
      this.delItems = [];
      if (!this.allChecked){
        for (var i = 0; i < this.items.length; i++){
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(key){
      //alert(key);
      if (key != ''){
        this.message = 'Buscando tablas';
        this.showProgress = true;
        let uri = '/tablas/search';
        this.axios.post(uri, { cmp_id: String(this.$store.state.company), key: key })
        .then(response => {
          console.log(response);
          this.items = response.data;
          this.showProgress = false;
          this.delItems = [];
          this.filter = true;
        })
        .catch(err => {
          this.showProgress = false;
          console.log(err);
          this.message = '¡Error al buscar las tablas' + err;
        });
      }
    },
    closeSearch(){
      this.filter = false;
      this.keywords = '';
      this.fetchItems();
    },
    newSearch () {
      this.search = {};
    },
    createItem(){
      this.$router.push({ name: 'CreateTabla', params: {add: true} });
    },
    editItem(id){
      this.$router.push({ name: 'CreateTabla', params: {id: id, add: false} });
    },
    exportReport(){
      var tableId = 'tablas';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.tta_nmbre = this.items[i].tpotabla_tablas[0] != undefined ? this.items[i].tpotabla_tablas[0].tta_nmbre : '';
        item.tav_nmro = this.items[i].tav_nmro;
        item.dep_cdgo = this.items[i].dependencia_tablas[0] != undefined ? this.items[i].dependencia_tablas[0].dep_cdgo : '';
        item.dep_nmbre = this.items[i].dependencia_tablas[0] != undefined ? this.items[i].dependencia_tablas[0].dep_nmbre : '';
        item.ser_cdgo = this.items[i].serie_tablas[0] != undefined ? this.items[i].serie_tablas[0].ser_cdgo : '';
        item.ser_nmbre = this.items[i].serie_tablas[0] != undefined ? this.items[i].serie_tablas[0].ser_nmbre : '';
        item.tab_tpg = this.items[i].tab_tpg;
        item.tab_tpc = this.items[i].tab_tpc;
        item.tab_cnsrvcn = this.items[i].tab_cnsrvcn ? 'Si' : 'No';
        item.tab_elmncn = this.items[i].tab_elmncn ? 'Si' : 'No';
        item.tab_dgtlzcn = this.items[i].tab_dgtlzcn ? 'Si' : 'No';
        item.tab_slccn = this.items[i].tab_slccn ? 'Si' : 'No';
        item.tab_prcdmnto = this.items[i].tab_prcdmnto;
        item.tab_prcdmnto = this.items[i].tab_prcdmnto;
        item.tab_activa = this.items[i].tab_activa ? 'Si' : 'No';

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.$store.state.companydata.cmp_nmbre],
        [moment(new Date()).format('DD/MMM/YYYY hh:mm A')],
        [],
      	["Tablas"],
        [],
        ["Tipo Tabla", "Versión", "Cód. Dependencia", "Dependencia", "Cód. Serie", "Serie", "Años en Gestión", "Años en Central", "Conservación", "Eliminación", "Digitalización", "Selección", "Procedimiento", "Activa"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A7" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }    
  }
}
</script>
